<template>
  <div id="member-subscription-list">
    <v-dialog v-model="previewRecordPrompt" max-width="75%">
      <efficiency-status-preview
        pageTitle="Efficiency Preview"
        pageDescription="Efficiency Preview"
        :previewRecordPrompt="previewRecordPrompt"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
        :recordData="selectedData"
        v-if="previewRecordPrompt"
      ></efficiency-status-preview>
    </v-dialog>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-container class="py-0" v-if="StartupLoadingFlag">
          <v-skeleton-loader v-bind="attrs" type="table-tbody"></v-skeleton-loader>
        </v-container>

        <v-form ref="form2" v-model="valid1" lazy-validation>
          <v-container v-if="!StartupLoadingFlag">
            <v-row wrap>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Area
                  </h6>
                </label>
                <v-autocomplete
                  @change="getZoneCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="AreaCodeOptionsLoading"
                  :items="AreaCodeOptions"
                  :rules="AreaCodeRules"
                  v-model="AreaCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    <span class="text-danger">*</span> Zone
                  </h6>
                </label>
                <v-autocomplete
                  @change="getRegionCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    Region
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="RegionCodeOptionsLoading"
                  :items="RegionCodeOptions"
                  :rules="RegionCodeRules"
                  v-model="RegionCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    Lom
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" md="12">
                <label>
                  <h6>
                    100% Efficiency
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  v-model="EfficiencyId"
                  :items="EfficiencyIdOptions"
                  :loading="EfficiencyIdOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  target="_blank"
                  color="#8950FC"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >Search</v-btn>
                <v-btn
                  @click.prevent="GenerateExcelReport"
                  target="_blank"
                  color="#8950FC"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >Generate Excel</v-btn>
                <v-btn
                  v-if="ExcelFileName != '' && ExcelDownloadFlag"
                  :href="ExcelFileName"
                  target="_blank"
                  color="#2fb65a"
                  elevation="30"
                  class="btn btn-primary font-size-h6 px-4 py-4 my-3 mr-3 white--text"
                >
                  <v-icon dark>mdi-download</v-icon>Download Report
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-container class="py-0" v-if="tableData1.length == 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>No records found.</h4>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search records here"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :search="search"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:item.ActiveStatusTxt="{ item }">
                  <v-chip
                    :color="getActiveStatusColor(item.ActiveStatusTxt)"
                    draggable
                    dark
                  >{{ item.ActiveStatusTxt }}</v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-tooltip bottom v-if="item.PreviewFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="blue"
                        @click="previewData(item, e)"
                        v-bind="attrs"
                        v-on="on"
                      >mdi-file-search</v-icon>
                    </template>
                    <span>Preview</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <br />
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import EfficiencyStatusPreview from "@/view/pages/erp/reports/LOEfficiencyStatusPreview.vue";

export default {
  mixins: [common],
  components: {
    EfficiencyStatusPreview
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      StartupLoadingFlag: false,
      SearchFlag: false,

      previewRecordPrompt: false,

      DownloadFlag: false,
      ExcelFileName: "",
      ExcelFields: {},

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",

      JciYearCode: "",

      AreaCodeRules: [v => !!v || "Area is required"],
      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      ZoneCodeRules: [v => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      RegionCodeRules: [],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      EfficiencyId: "",
      EfficiencyIdOptions: [],
      EfficiencyIdOptionsLoading: false,

      ExcelFileName: "",
      ExcelDownloadFlag: false,

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {}
    };
  },
  mounted() {},
  watch: {
    PageInfo: function() {
      console.log("watch PageInfo");
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.getAreaCodeOptions();
        this.getEfficiencyIdOptions();
      }
    },
    AreaCodeOptions: function() {
      console.log("watch AreaCodeOptions");
      this.AreaCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
    ZoneCodeOptions: function() {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    RegionCodeOptions: function() {
      console.log("watch RegionCodeOptions");
      this.RegionCodeOptionsLoading = false;
    },
    LomCodeOptions: function() {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
    EfficiencyIdOptions: function() {
      console.log("watch EfficiencyIdOptions");
      this.LoadingFlag = false;
      this.EfficiencyIdOptionsLoading = false;
    }
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form2.validate()) {
        this.snackbar = true;
      }
    },
    resetForm() {
      this.search = "";
      this.selected = [];
      this.$refs.form3.reset();
    },
    resetValidation() {
      this.$refs.form2.resetValidation();
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "efficiency_report",
        Action: "report_2"
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getEfficiencyIdOptions() {
      console.log("getEfficiencyIdOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.LoadingFlag = true;
        this.EfficiencyIdOptionsLoading = true;
        var selectbox1_source = "EfficiencyId";
        var selectbox1_destination = "EfficiencyIdOptions";
        var selectbox1_url = "api/year-wise-efficiency/options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          Year: yearCode,
          NotAvailable: 1
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getAreaCodeOptions() {
      console.log("getAreaCodeOptions called");
      this.AreaCodeOptionsLoading = true;
      var selectbox1_source = "AreaCode";
      var selectbox1_destination = "AreaCodeOptions";
      var selectbox1_url = "api/year-wise-lom/area-options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        YearCode: this.JciYearCode
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.tableData1 = [];
      this.ZoneCode = "";
      this.RegionCode = "";
      this.LomCode = "";
      this.ZoneCodeOptions = [];
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];

      var yearCode = this.JciYearCode;
      var areaCode = this.AreaCode;
      console.log("yearCode=" + yearCode + ", areaCode=" + areaCode);
      if (yearCode != "" && areaCode != "") {
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 6,
          AreaCode: areaCode
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getRegionCodeOptions() {
      console.log("getRegionCodeOptions called");
      this.tableData1 = [];
      this.RegionCode = "";
      this.LomCode = "";
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];

      var YearCode = this.JciYearCode;
      var AreaCode = this.AreaCode;
      var ZoneCode = this.ZoneCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", AreaCode=" +
          AreaCode +
          ", ZoneCode=" +
          ZoneCode
      );
      if (YearCode != "" && AreaCode != "" && ZoneCode != "") {
        this.RegionCode = "";
        this.RegionCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "RegionCode";
        var selectbox1_destination = "RegionCodeOptions";
        var selectbox1_url = "api/year-wise-lom/region-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          AreaCode: AreaCode,
          ZoneCode: ZoneCode
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      this.tableData1 = [];
      this.LomCode = "";
      this.LomCodeOptions = [];

      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      var RegionCode = this.RegionCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", ZoneCode=" +
          ZoneCode +
          ", RegionCode=" +
          RegionCode
      );
      if (YearCode != "" && ZoneCode != "" && RegionCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 4,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          RegionCode: RegionCode
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        if (RegionCode == "") {
          message += "Region should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message
        };
      }
    },
    searchForm() {
      console.log("searchForm called");

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      var YearCode = this.JciYearCode;
      var AreaCode = this.AreaCode;
      var ZoneCode = this.ZoneCode;
      var RegionCode = this.RegionCode;
      var LomCode = this.LomCode;
      var EfficiencyId = this.EfficiencyId;
      console.log(
        "YearCode=" +
          YearCode +
          ", AreaCode=" +
          AreaCode +
          ", ZoneCode=" +
          ZoneCode +
          ", RegionCode=" +
          RegionCode +
          ", LomCode=" +
          LomCode +
          ", EfficiencyId=" +
          EfficiencyId
      );

      if (validate1) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/reports/efficiency-status";
        var upload = {
          UserInterface: 1,
          Year: YearCode,
          Area: AreaCode,
          Zone: ZoneCode,
          Region: RegionCode,
          Lom: LomCode,
          Criteria: EfficiencyId
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.LoadingFlag = true;
        this.SearchFlag = true;
        this.tableData1 = [];

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            var options = records.TableOptions;

            if (flag == 1) {
              thisIns.tableColumns1 = records.TableHeader;
              thisIns.tableOptions1 = options;
              thisIns.tableData1 = records.TableData;

              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.LoadingFlag = false;
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "LOM should not be empty.";
        console.log("error=" + message);
      }
    },
    GenerateExcelReport() {
      console.log("GenerateExcelReport called");

      var validate1 = this.$refs.form2.validate();
      console.log("validate1=" + validate1);

      var YearCode = this.JciYearCode;
      var AreaCode = this.AreaCode;
      var ZoneCode = this.ZoneCode;
      var RegionCode = this.RegionCode;
      var LomCode = this.LomCode;
      var EfficiencyId = this.EfficiencyId;
      console.log(
        "YearCode=" +
          YearCode +
          ", AreaCode=" +
          AreaCode +
          ", ZoneCode=" +
          ZoneCode +
          ", RegionCode=" +
          RegionCode +
          ", LomCode=" +
          LomCode +
          ", EfficiencyId=" +
          EfficiencyId
      );

      var LomCode = this.LomCode;
      console.log("LomCode=" + LomCode);

      if (validate1) {
        this.ExcelFileName = "";
        this.ExcelDownloadFlag = false;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/reports/generate-efficiency-report";
        var upload = {
          UserInterface: 2,
          Year: YearCode,
          Area: AreaCode,
          Zone: ZoneCode,
          Region: RegionCode,
          Lom: LomCode,
          Criteria: EfficiencyId
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.ResultFlag = false;
        this.LoadingFlag = true;
        this.StartupLoadingFlag = true;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
          },
          onUploadProgress: function(progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this)
        })
          .then(function(response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ResultFlag = false;
            thisIns.LoadingFlag = false;
            thisIns.StartupLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ExcelFileName = records.ExcelFileName;
              thisIns.ExcelDownloadFlag = records.ExcelDownloadFlag;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function(error) {
            console.log("error=" + error);
            thisIns.toast("error", error);
            thisIns.ResultFlag = false;
            thisIns.LoadingFlag = false;
            thisIns.StartupLoadingFlag = false;
          });
      } else {
        var message = "LOM should not be empty.";
        console.log("error=" + message);
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(tr) {
      console.log("previewData called");
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    }
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#member-subscription-list {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>